//@ts-nocheck
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { toast } from "react-toastify";
import { downloadPresignedFile, updateTest } from "../../../../api/tests";
import Test from "../../../../interfaces/tests";
import AddTest from "./AddTest";

interface ViewTestProps {
  tests: Test[];
  groupUserID: string;
  setOpenDialog?: Dispatch<SetStateAction<boolean>>;
  getCurrentGroupUsersFilter?: (
    id: string,
    searchInput: string,
    paidMonth: { name: string; value: number }[],
  ) => void;
  paramsId?: any;
  searchInput?: any;
  paidMonth?: { name: string; value: number };
  getTestForUser?: any;
}

const tableNav = [
  {
    title: "Test Name",
    id: 1,
  },
  {
    title: "Evaluation",
    id: 2,
  },
  {
    title: "Date",
    id: 3,
  },
  {
    title: "Student Comment",
    id: 4,
  },
  {
    title: "File",
    id: 5,
  },
];

const ViewTest = ({
  tests,
  groupUserID,
  setOpenDialog,
  getCurrentGroupUsersFilter,
  paramsId,
  searchInput,
  paidMonth,
  getTestForUser,
}: ViewTestProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [newTest, setNewTest] = useState(false);
  const [loadingTestId, setLoadingTestId] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    testId: string,
  ) => {
    if (event.target.files && event.target.files[0]) {
      await uploadFile(testId, event.target.files[0]);
    }
  };

  const handleBoxClick = (testId: string) => {
    setLoadingTestId(testId);
    fileInputRef.current?.click();
  };

  const uploadFile = async (testId: string, file: any) => {
    try {
      setLoadingTestId(testId);
      const response = await updateTest(testId, file);
      if ("error" in response) {
        toast.error("Something went wrong");
      } else {
        setOpenDialog(false),
          getTestForUser(groupUserID),
          getCurrentGroupUsersFilter(paramsId, searchInput, paidMonth),
          setLoadingTestId(null);
        toast.success("File Uploaded Successfully");
      }
    } catch (error) {
      console.error("Error Uploading file:", error);
      toast.error("Error Uploading file");
    }
  };

  const downloadFile = async (testId: string) => {
    try {
      setLoadingTestId(testId);
      const test = tests.find((test) => test._id === testId);
      if (test) {
        await downloadPresignedFile(test.file_url);
        setLoadingTestId(null);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    } finally {
      setLoadingTestId(null);
    }
  };
  return (
    <>
      {newTest ? (
        <AddTest
          groupUserID={groupUserID}
          setOpenDialog={setOpenDialog}
          getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
          paramsId={paramsId}
          searchInput={searchInput}
          paidMonth={paidMonth}
        />
      ) : (
        <div>
          <DialogTitle id="alert-dialog-title" sx={{ ml: "-25px" }}>
            View Tests
          </DialogTitle>
          <Typography variant="subtitle2" fontWeight={400} mb={3}>
            <strong>Student: </strong>
            {tests[0]?.groupUserID?.student.first_name}{" "}
            {tests[0]?.groupUserID?.student.last_name}
          </Typography>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Tests
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ height: 300, overflowX: "auto" }}>
                <Grid
                  container
                  sx={{
                    maxWidth: "100%",
                    flexWrap: "nowrap",
                    width: "100%",
                    minWidth: "650px",
                  }}
                >
                  {tableNav?.map((entry: any) => (
                    <Grid key={entry.id} item xs={entry.id === 4 ? 3 : 2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          pl: entry.id === 5 || entry.id === 1 ? 0 : "35px",
                        }}
                      >
                        <div
                          style={{
                            width: entry.id === 1 ? "0px" : "2px",
                            marginRight: entry.id === 1 ? "0px" : "5px",
                            height: "15px",
                            backgroundColor: "#00000030",
                          }}
                        />
                        <Typography
                          whiteSpace="nowrap"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {entry.title}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {tests &&
                  tests.map((test: Test, index: any) => (
                    <Grid
                      key={index}
                      container
                      sx={{
                        minWidth: "100%",
                        flexWrap: "nowrap",
                        mt: 2,
                        borderBottom: "1px solid #00000030",
                        pb: "5px",
                        "div:first-of-type ": {
                          ".text": {
                            pl: 0,
                          },

                          pl: "0",
                        },
                        ".text": {
                          pl: "35px",
                        },
                        minWidth: "650px",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography
                          className="text"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {test?.test_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          className="text"
                          whiteSpace="nowrap"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {test?.evaluation}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          className="text"
                          whiteSpace="nowrap"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {dayjs(test?.held_date).format("DD.MM.YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip title={test?.studentComment}>
                          <Typography
                            className="text"
                            whiteSpace="nowrap"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              maxWidth: "90%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {test?.studentComment === ""
                              ? "No comment"
                              : `${test?.studentComment}`}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        {test.file_url === "" ? (
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              minWidth: "130px",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => handleBoxClick()}
                          >
                            {loadingTestId === test._id ? (
                              <Box width={20} height={20}>
                                <CircularProgress size={20} />
                              </Box>
                            ) : (
                              "Upload"
                            )}
                            <CloudUploadIcon />
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => handleFileUpload(event, test._id)}
                            />
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "130px",
                              gap: 1,
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => {
                              downloadFile(test._id as string);
                            }}
                          >
                            {loadingTestId === test._id ? (
                              <Box sx={{ width: "20px", height: "20px" }}>
                                <CircularProgress size={20} />
                              </Box>
                            ) : (
                              "Download"
                            )}
                            <CloudDownloadIcon />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => setNewTest(true)}
          >
            Add New Test
          </Button>
        </div>
      )}
    </>
  );
};

export default ViewTest;
