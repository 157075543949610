import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  control: any;
  error: boolean;
  type?: string;
  size?: any;
  disabled?:boolean
}

function Input({ name, label, control, error, type, size , disabled }: Props) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <FormControl fullWidth margin={"dense"}>
          <TextField
            error={error}
            {...field}
            type={type || name}
            label={label}
            variant="outlined"
            size={size}
            disabled={disabled}
          />
        </FormControl>
      )}
    />
  );
}

export default Input;
