import Layout from "../component/template/Layout";
import Index from "../component/teacher";

function Teachers() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Teachers;
