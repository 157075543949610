export const formatDate = (
  date: Date,
  formatOptions: Intl.DateTimeFormatOptions & {
    hour?: '2-digit' | 'numeric';
    minute?: '2-digit' | 'numeric';
  } = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }
): string => {
  const mergedOptions = {
    day: formatOptions.day || '2-digit',
    month: formatOptions.month || '2-digit',
    year: formatOptions.year || '2-digit',
    hour: formatOptions.hour,
    minute: formatOptions.minute,
  };
  return date?.toLocaleDateString("en-GB", mergedOptions);
};
