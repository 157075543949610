import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Dispatch, SetStateAction } from "react";

interface Props {
  values: any[];
  setValues: Dispatch<SetStateAction<any[]>>;
  options: any[];
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

function MultiSelect({
  values,
  setValues,
  options,
  label,
  fullWidth,
  disabled,
}: Props) {
  return (
    <FormControl
      size="small"
      sx={{
        m: fullWidth ? 0 : 1,
        width: { xs: "100%", lg: fullWidth ? "95%" : "300px" },
      }}
    >
      <InputLabel sx={{ mr: 1 }} id="demo-multiple-checkbox-label">
        {label}
      </InputLabel>

      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={values}
        onChange={(e: any) => {
          setValues(e.target.value);
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map((x: any) => x.name).join(", ")}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Checkbox
              checked={values.some((item) => item.value === option.value)}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
