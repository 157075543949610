import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import { DatePicker } from "@mui/x-date-pickers";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  changeGroupStatus,
  createGroup,
  getGroup,
  updateGroup,
} from "../../../api/group";
import { getLevels } from "../../../api/levels";
import { getUserByRole } from "../../../api/user";
import useAuth from "../../../context/useAuth";
import IDateRange from "../../../interfaces/dateRange";
import Group from "../../../interfaces/group";
import Form from "../../form/Form";
import Input from "../../form/Input";
import Select from "../../form/Select";
import EndGroupModal from "../endGroupModal";
import { scheduleSchema, schema, weekDays } from "../utils";

const Index = () => {
  const { locations, user } = useAuth();
  const navigate = useNavigate();
  let params = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [teachers, setTeachers] = useState<any[]>([]);
  const [isGroupLoading, setIsGroupLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<Group>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [endGroupModal, setEndGroupModal] = useState(false);
  const [startOpen, setStartOpen] = useState<boolean>(false);
  const [levels, setLevels] = useState<any>([]);
  const [selected, setSelected] = useState<{ day: number; time: string }>({
    day: 0,
    time: "",
  });
  useEffect(() => {}, [selected.time]);

  const [dateRange, setDateRange] = useState<IDateRange>({
    start_date: dayjs(new Date()).valueOf(),
    end_date: dayjs(new Date()).valueOf(),
  });

  const handleDateChange = (
    date: Dayjs | null,
    type: "start_date" | "end_date",
  ) => {
    if (date) {
      const dayjsDate = dayjs(date);
      const timestamp = dayjsDate.valueOf();
      setDateRange((prev: any) => ({ ...prev, [type]: dayjsDate }));
      setValue(type, timestamp);

      if (type === "start_date" && watch("language_level")) {
        const selectedLevel = levels.find(
          (level: any) => level._id === watch("language_level"),
        );
        if (selectedLevel) {
          const newEndDate = dayjs(date)
            .add(selectedLevel.monthlyLength, "month")
            .valueOf();
          setDateRange((prev) => ({ ...prev, end_date: newEndDate }));
          setValue("end_date", newEndDate);
        }
      }
    }
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    if (!params.id) {
      newGroup(data);
    } else {
      editGroup(data);
    }
  };

  const endGroup = () => {
    _changeGroupStatus({ id: group?._id, is_active: !group?.is_active });
  };

  useEffect(() => {
    getTeachers();
    if (params.id) {
      getCurrentGroup(params.id);
    }
  }, [params]);

  const getCurrentGroup = async (id: any) => {
    setIsGroupLoading(true);
    const res = await getGroup(id);

    if ("error" in res) {
      setIsGroupLoading(false);
      return toast.error(res.message);
    } else {
      setDateRange({
        start_date: res.start_date,
        end_date: res.end_date,
      });

      setValue("teacher", res.teacher._id ? res.teacher._id : "");
      setValue("name", res.name);
      setValue("location", res.location);
      setValue("start_date", res.start_date);
      setValue("end_date", res.end_date);
      setValue("language_level", res.language_level._id);
      setValue("schedule", res.schedule);
      setGroup(res);
      setIsGroupLoading(false);
    }
  };

  const getTeachers = async () => {
    const res = await getUserByRole("4");

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let _teachers = res.map((teacher: any) => {
        return {
          ...teacher,
          value: teacher._id,
          name: teacher.first_name + " " + teacher.last_name,
        };
      });

      setTeachers(_teachers);
    }
  };

  const newGroup = async (data: any) => {
    setIsFetching(true);
    const res = await createGroup({ ...data });

    if ("error" in res) {
      setIsFetching(false);

      return toast.error(res.message);
    }

    toast.success("Group created successfully");
    setIsFetching(false);
    navigate("/groups");
  };

  const _changeGroupStatus = async (data: any) => {
    if (params.id) {
      setIsFetching(true);

      const res = await changeGroupStatus(data?.is_active, data?.id);

      if ("error" in res) {
        setIsFetching(false);

        return toast.error(res.message ? res.message : "Server error");
      }

      toast.success("Group edited successfully");
      setIsFetching(false);
      setGroup(res);
    }
  };

  const editGroup = async (data: any) => {
    if (params.id) {
      setIsFetching(true);

      const res = await updateGroup(params.id, data);

      if ("error" in res) {
        setIsFetching(false);

        return toast.error(res.message ? res.message : "Server error");
      }

      toast.success("Group edited successfully");
      setIsFetching(false);
      setGroup(res);
    }
  };

  const scheduleDetails = watch("schedule");

  const handleSchedule = async () => {
    try {
      await scheduleSchema.validate(selected);

      if (scheduleDetails) {
        setValue("schedule", [...scheduleDetails, selected]);
      } else {
        setValue("schedule", [selected]);
      }
      setSelected({ day: 0, time: "" });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        toast.error(error.message);
      }
    }
  };

  const fetchLevels = async () => {
    const res = await getLevels();
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      return setLevels(res.data);
    }
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const sortScheduleByDay = () => {
    if (scheduleDetails) {
      const sortedSchedule = [...scheduleDetails].sort((a, b) => a.day - b.day);
      return sortedSchedule;
    }
    return [];
  };

  const sortedScheduleDetails = sortScheduleByDay();
  const handleDeleteSchedule = (index: number) => {
    const updatedSchedule = [...scheduleDetails];
    updatedSchedule.splice(index, 1);
    setValue("schedule", updatedSchedule);
  };
  {
    return (
      <>
        {(user?.user_level === 1 ||
          user?.user_level === 2 ||
          user?.user_level === 3) && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography gutterBottom variant="h5" component="div">
                  Edit Group
                </Typography>{" "}
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {!isGroupLoading ? (
                  <>
                    <Select
                      name="teacher"
                      label={"Teacher"}
                      setValue={setValue}
                      options={teachers}
                      control={control}
                      error={!!errors.teacher}
                    />
                    <Input
                      name="name"
                      label={"Class Name"}
                      control={control}
                      error={!!errors.name}
                    />
                    <Select
                      name="language_level"
                      label={"Language Level"}
                      setValue={setValue}
                      options={levels.map((level: any) => ({
                        ...level,
                        value: level._id,
                        label: level.level,
                      }))}
                      control={control}
                      error={!!errors.language_level}
                      levels
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>,
                      ) => {
                        const selectedLevel = levels.find(
                          (level: any) => level._id === event.target.value,
                        );
                        if (selectedLevel) {
                          setValue("language_level", event.target.value);
                          const newEndDate = dayjs(dateRange.start_date)
                            .add(selectedLevel.monthlyLength, "month")
                            .valueOf();
                          setDateRange((prev: any) => ({
                            ...prev,
                            end_date: newEndDate,
                          }));
                          setValue("end_date", newEndDate);
                        }
                      }}
                    />
                    <Select
                      name="location"
                      label={"Location"}
                      setValue={setValue}
                      options={locations}
                      control={control}
                      error={!!errors.location}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        mt: "5px",
                      }}
                    >
                      <DatePicker
                        open={startOpen}
                        onOpen={() => setStartOpen(true)}
                        onClose={() => setStartOpen(false)}
                        format="DD/MM/YYYY"
                        label="Start Date"
                        value={
                          dateRange.start_date
                            ? dayjs(dateRange.start_date)
                            : null
                        }
                        onChange={(date) => {
                          handleDateChange(date, "start_date");
                        }}
                        slotProps={{
                          textField: {
                            onClick: () => setStartOpen(true),
                          },
                        }}
                      />
                      <DatePicker
                        disabled
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={
                          dateRange.end_date ? dayjs(dateRange.end_date) : null
                        }
                        readOnly
                      />
                    </Box>
                    <Grid container mt={5} spacing={4}>
                      <Grid item container xs={12} md={5}>
                        <Card
                          sx={{
                            width: "100%",
                            display: "flex",
                            gap: "15px",
                            p: 2,
                          }}
                        >
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                              }}
                            >
                              {weekDays.map((ent: any, index: any) => {
                                const isDisabled = scheduleDetails?.some(
                                  (schedule: any) =>
                                    schedule.day === ent?.value,
                                );
                                return (
                                  <Chip
                                    key={index}
                                    color={
                                      selected.day === ent.value
                                        ? "primary"
                                        : "default"
                                    }
                                    label={ent.label}
                                    clickable
                                    onClick={() =>
                                      setSelected((prev) => ({
                                        ...prev,
                                        day: ent.value,
                                      }))
                                    }
                                    sx={{ maxWidth: "150px" }}
                                    disabled={isDisabled}
                                  />
                                );
                              })}
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <MultiSectionDigitalClock
                              skipDisabled
                              sx={{
                                height: "100%",
                                width: "auto",
                                borderBottom: "none",
                                "> ::-webkit-scrollbar": {
                                  display: "none",
                                },
                                "> ul": {
                                  maxHeight: "auto",
                                  borderLeft: "none !important",
                                },
                              }}
                              onChange={(newTime) => {
                                setSelected((prev) => ({
                                  ...prev,
                                  time: dayjs(newTime).format("HH:mm"),
                                }));
                              }}
                              ampm={false}
                              minTime={dayjs().set("hour", 7).set("minutes", 0)}
                              maxTime={dayjs()
                                .set("hour", 22)
                                .set("minutes", 59)}
                              disableIgnoringDatePartForTimeValidation={true}
                              views={["hours", "minutes"]}
                            />
                          </Grid>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleSchedule();
                            }}
                            sx={{
                              borderRadius: "5px",
                              height: "40px",
                              width: "50px",
                              mt: 0.5,
                            }}
                          >
                            Add
                          </Button>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                          display: "flex",
                          gap: 3,
                          flexWrap: "wrap",
                          color: errors?.schedule ? "#D32F2F" : "#111111",
                        }}
                      >
                        <Card sx={{ width: "100%", p: 2 }}>
                          <Typography variant="h6" sx={{ fontWeight: "400" }}>
                            Schedule
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "20px",
                              mt: 2,
                              flexWrap: "wrap",
                            }}
                          >
                            {sortedScheduleDetails.map(
                              (oneSch: any, index: any) => {
                                const selectedWeekDay = weekDays.find(
                                  (item: any) => item.value === oneSch.day,
                                );
                                return (
                                  <Chip
                                    key={index}
                                    label={`${selectedWeekDay?.label} - ${oneSch?.time}`}
                                    sx={{ maxWidth: "200px", mb: 1 }}
                                    onDelete={() => handleDeleteSchedule(index)}
                                    color="primary"
                                  />
                                );
                              },
                            )}
                          </Box>
                        </Card>
                      </Grid>
                    </Grid>

                    <Box width="100%" display="flex">
                      <LoadingButton
                        loading={isFetching}
                        sx={{ mt: 2 }}
                        type="submit"
                        variant="contained"
                        onClick={() =>
                          setValue(
                            "start_date",
                            dayjs(dateRange.start_date).valueOf(),
                          )
                        }
                      >
                        {params.id ? "Save Changes" : "Create"}
                      </LoadingButton>
                      {params.id && group?.is_active && (
                        <Button
                          sx={{ mt: 2, ml: "auto" }}
                          variant="contained"
                          color="error"
                          onClick={() => setEndGroupModal(true)}
                        >
                          End Group
                        </Button>
                      )}
                    </Box>
                    {endGroupModal && (
                      <EndGroupModal
                        open={endGroupModal}
                        handleClose={setEndGroupModal}
                        handleClick={endGroup}
                        action={group?.is_active || false}
                      />
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 450,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Form>
        )}
      </>
    );
  }
};
export default Index;
