import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, IconButton, Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentDone from "../../../interfaces/payment-done";
import { formatDate } from "../../../utils/formatDate";

interface Props {
  data: PaymentDone[];
  loading: boolean;
  deleteLoading: boolean;
  deletePayment: (id: string) => void;
}

const List: React.FC<Props> = ({
  data,
  loading,
  deleteLoading,
  deletePayment,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "sent_to",
      headerName: "Sent to",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "received_from",
      headerName: "Received From",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "ammount",
      headerName: "Ammount",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Created Date",
      valueGetter: (params: GridValueGetterParams) =>
        `${formatDate(new Date(params.row.date), {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created_by",
      headerName: "Created By",
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.created_by.first_name +
          " " +
          params.row.created_by.last_name
        }`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "updatedAt",
      headerName: "Last Edit",
      valueGetter: (params: GridValueGetterParams) =>
        `${formatDate(new Date(params.row.updatedAt), {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params: GridRenderCellParams) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <div>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/payments-done/${params.id}`), handleClose();
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#A250A6" }}
                >
                  View
                  <VisibilityIcon />
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => (
                  handleClose(), handleClickOpen(), setId(params.row._id)
                )}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#DA5050" }}
                >
                  Delete
                  <DeleteIcon />
                </Box>
              </MenuItem>
            </Menu>
          </div>
        );
      },
      flex: 1,
      minWidth: 150,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          Are you sure you want to delete this sent payment?
        </DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            color="error"
            onClick={async () => {
              await deletePayment(id);
              handleClose();
            }}
          >
            Delete
          </LoadingButton>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <div style={{ height: 380, width: "100%", background: "#fff" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
        />
      </div>
    </>
  );
};

export default List;
