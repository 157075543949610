import api from "../utils/api";
import ErrorInterface from "../interfaces/error";
import Payment from "../interfaces/payment";

export async function getTeacherCount(
  checkedLocation?: any[]
): Promise<
  { count: { activeCount: string; notActiveCount: string } } | ErrorInterface
> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l: any) => l.value);
    }
    const response = await api.get(`dashboard/users/getTeachersCount`, {
      params: { location },
    });
    return { count: response.data.data };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getCombinedCounts(checkedLocation?: any[]): Promise<
  | {
      activeGroupCount: number;
      notActiveGroupCount: number;
      teacherCount: number;
      groupUsersCount: number;
      totalUserCount: number;
    }
  | ErrorInterface
> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l: any) => l.value);
    }
    const { data } = await api.get(`dashboard/getCombinedCounts`, {
      params: { location },
    });
    return data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getStudentsCount(
  checkedLocation?: any[]
): Promise<
  { count: { activeCount: string; notActiveCount: string } } | ErrorInterface
> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l: any) => l.value);
    }

    const response = await api.get(`dashboard/group-users/getCount`, {
      params: { location },
    });
    return { count: response.data.data };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getGroupsCount(
  checkedLocation?: any[]
): Promise<
  { count: { activeCount: string; notActiveCount: string } } | ErrorInterface
> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l: any) => l.value);
    }

    const response = await api.get(`dashboard/groups/getCount`, {
      params: { location },
    });
    return { count: response.data.data };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getPaymentsCount(): Promise<
  { count: string } | ErrorInterface
> {
  try {
    const response = await api.get(`dashboard/group-users/paymentsDone`);
    return { count: response.data.data };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getPaymentsStats(
  query: string
): Promise<Payment[] | ErrorInterface> {
  try {
    const response = await api.get(
      `/dashboard/payment-report?${query.length > 1 ? query : ""}`
    );

    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
