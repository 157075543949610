import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Input from "../form/Input";
import Form from "../form/Form";
import Select from "../form/Select";
import { getUser, updateUser } from "../../api/user";
import User from "../../interfaces/user";
import { genders } from "../../utils/genders";
import { toast } from "react-toastify";
import useAuth from "../../context/useAuth";
import { Box, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ChangePassword from "./ChangePassword";

const editProfileSchema = yup
  .object({
    last_name: yup.string().required(),
    first_name: yup.string().required(),
    gender: yup.number().required(),
    phone_number: yup.string().required(),
    identity_card: yup.string().required(),
    location: yup.string().required(),
  })
  .required();

const Index = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { user, locations } = useAuth();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(editProfileSchema),
  });

  const onSubmit: SubmitHandler<User> = (data) => {
    editUser(data);
  };

  useEffect(() => {
    if (user) {
      fetchUser(user?.id);
    }
  }, [user]);

  const fetchUser = async (id: any) => {
    const res = await getUser(id);

    if ("error" in res) {
      setLoading(false);

      return toast.error(res.message);
    } else {
      setValue("last_name", res.last_name);
      setValue("first_name", res.first_name);
      setValue("gender", res.gender);
      setValue("phone_number", res.phone_number);
      setValue("location", res.location._id);
      setValue("identity_card", res.identity_card);
      setLoading(false);
    }
  };

  const editUser = async (data: any) => {
    if (user?.id) {
      setEditLoading(true);
      const res = await updateUser(user?.id, data);

      if ("error" in res) {
        setEditLoading(true);

        return toast.error(res.message ? res.message : "Server error");
      }

      toast.success("User edited successfully");
      setEditLoading(false);
    }
  };

  return (
    <>
      <ChangePassword open={open} setOpen={setOpen} />
      <Form>
        <Card sx={{ padding: 2 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5" component="div">
                Profile
              </Typography>
              <Button onClick={() => setOpen(true)} variant="contained">
                Change Password
              </Button>
            </Box>

            {!loading ? (
              <>
                <Input
                  name="first_name"
                  label={"First Name"}
                  control={control}
                  error={!!errors.first_name}
                />
                <Input
                  name="last_name"
                  label={"Last Name"}
                  control={control}
                  error={!!errors.last_name}
                />

                <Select
                  name="gender"
                  label={"Gender"}
                  setValue={setValue}
                  options={genders}
                  control={control}
                  error={!!errors.gender}
                />

                <Input
                  name="phone_number"
                  label={"Phone"}
                  control={control}
                  error={!!errors.phone_number}
                />
                <Select
                  name="location"
                  label={"City/Location"}
                  setValue={setValue}
                  options={locations}
                  control={control}
                  error={!!errors.location}
                />
                <Input
                  name="identity_card"
                  label={"ID Card"}
                  control={control}
                  error={!!errors.identity_card}
                />
                <LoadingButton
                  loading={editLoading}
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  Edit Profile
                </LoadingButton>
              </>
            ) : (
              <Box
                sx={{
                  height: 450,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </CardContent>
        </Card>
      </Form>
    </>
  );
};

export default Index;
