import DownloadIcon from "@mui/icons-material/Download";
import { Card, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import saveAs from "file-saver";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getBalanceTable,
  getCreditTables,
  getDebitTables,
  getFinancesCSV,
  getUnpaidTable,
} from "../../api/finance";
import { tableTypeColumns } from "../../utils/financesTable";
import MultiSelect from "../form/MultiSelect";
import useAuth from "../../context/useAuth";
import { formatDate } from "../../utils/formatDate";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import IDateRange from "../../interfaces/dateRange";
import dayjs, { Dayjs } from "dayjs";

interface StudentOptions {
  email: string;
  first_name: string;
  last_name: string;
  gender: string;
  phone_number: string;
  location: string;
  identity_card: string;
  id: string;
}
interface ApiCallParams {
  dateRange: IDateRange;
  page: number;
}
export type TableType = "debit" | "credit" | "unpaid" | "combined";

const Finance = () => {
  const { locations } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentOptions[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>({
    start_date: null,
    end_date: null,
  });
  const [selectedTable, setSelectedTable] = useState<TableType>("debit");
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState(0);
  const [checkedLocation, setCheckedLocation] = useState<any[]>([]);
  const [openStart, setOpenStart] = useState<boolean>(false);
  const [openEnd, setOpenEnd] = useState<boolean>(false);

  const apiCalls: Record<TableType, (params: ApiCallParams) => Promise<any>> = {
    debit: (params) => getDebitTables(params.dateRange, params.page),
    credit: (params) => getCreditTables(params.dateRange, params.page),
    unpaid: (params) =>
      getUnpaidTable(
        params.dateRange,
        params.page,
        checkedLocation
      ),
    combined: (params) => getBalanceTable(params.dateRange, params.page),
  };

  const transformData = (tableType: TableType, rawData: any[]): any[] => {
    switch (tableType) {
      case "debit":
        return rawData.map((d: any) => ({
          Timestamp: formatDate(new Date(d.debit_timestamp), {
            hour: "2-digit",
            minute: "2-digit",
          }),
          Student: `${d.student_id.first_name}  ${d.student_id.last_name}`,
          Group: d.group_id?.name,
          Location: d.location.name,
          Amount: d.debit_amount,
          id: d._id,
        }));
      case "credit":
        return rawData.map((c: any) => ({
          Timestamp: formatDate(new Date(c.credit_timestamp), {
            hour: "2-digit",
            minute: "2-digit",
          }),
          User: `${c.user_id.first_name}  ${c.user_id.last_name}`,
          Comment: c.comment,
          Location: c.location.name,
          Amount: c.credit_amount,
          id: c._id,
        }));
      case "unpaid":
        return rawData.map((c: any) => {
          return {
            Student: `${c.student.first_name}  ${c.student.last_name}`,
            Group: c.group.name,
            GroupStartDate: formatDate(new Date(c.group.start_date), {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            GroupEndDate: formatDate(new Date(c.group.end_date), {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
            FirstMonth: c.payment[0].status,
            SecondMonth: c.payment[1].status,
            ThirdMonth: c.payment[2].status,
            FourthMonth: c.payment[3]?.status,
            FifthMonth: c.payment[4]?.status,
            SixthMonth: c.payment[5]?.status,
            id: c._id,
          };
        });
      case "combined":
        return rawData.map((d: any) => ({
          Timestamp: formatDate(new Date(d.timestamp), {
            hour: "2-digit",
            minute: "2-digit",
          }),
          User: d.name,
          Group: d.group,
          Debit_Amount: d.debit_amount,
          Credit_Amount: d.credit_amount,
          Credit_Description: d.comment,
          id: d._id,
        }));
      default:
        return [];
    }
  };

  const handleDateRangeChange = (
    newDate: Dayjs | null,
    dateType: "start_date" | "end_date",
  ) => {
    setDateRange((prev: IDateRange) => ({
      ...prev,
      [dateType]: newDate?.valueOf(),
    }));
  };

  const downloadCSV = async () => {
    const res = await getFinancesCSV(dateRange, selectedTable, checkedLocation);
    const tableType =
      selectedTable.charAt(0).toUpperCase() + selectedTable.slice(1);
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return saveAs(
        res,
        `SPXEDU-Finances/${tableType} - (${new Date().toLocaleDateString(
          "en-EN",
          options,
        )}).csv`,
      );
    }
  };

  const fetchDataByTableType = async (
    tableType: TableType,
    params: ApiCallParams,
  ) => {
    setLoading(true);
    try {
      const { data } = await apiCalls[tableType](params);
      if ("error" in data) {
        toast.error(data.message);
        setStudents([]);
        setRecords(0);
      } else {
        const transformedData = transformData(tableType, data.data);
        setStudents(transformedData);
        setRecords(data.records);
      }
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
    setColumns(tableTypeColumns[tableType]);
  };

  const fetchDataAndSetColumns = async (tableType: TableType) => {
    setLoading(true);
    try {
      const locationsCheck = checkedLocation.map((location) => location.value);
      const params = { dateRange, page, locations: locationsCheck };
      await fetchDataByTableType(tableType, params);
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
    setColumns(tableTypeColumns[tableType]);
  };

  useEffect(() => {
    fetchDataAndSetColumns(selectedTable);
  }, [selectedTable, dateRange, page, checkedLocation]);

  return (
    <>
      <Card elevation={0} sx={{ py: 2, px: 2 }}>
        <Typography variant="h5">Finances</Typography>
        <Typography variant="subtitle1">
          {selectedTable &&
            selectedTable.charAt(0).toUpperCase() + selectedTable.slice(1)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            py: 2,
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: { xs: 2, lg: 2 },
            }}
          >
            {Object.keys(tableTypeColumns).map((tableType) => (
              <Button
                key={tableType}
                variant={selectedTable === tableType ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedTable(tableType as TableType);
                  setDateRange({ start_date: null, end_date: null });
                  setPage(0);
                }}
              >
                <Typography>
                  {tableType.charAt(0).toUpperCase() + tableType.slice(1)}
                </Typography>
              </Button>
            ))}
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              gap={{ xs: 2, lg: 0 }}
            >
              <Grid item xs={12} md={6} lg={3}>
                {selectedTable === "unpaid" && (
                  <MultiSelect
                    values={checkedLocation}
                    setValues={setCheckedLocation}
                    options={locations}
                    label="Location"
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", gap: "5px", mr: { xs: 0, md: 1 } }}>
                  <DatePicker
                    open={openStart}
                    onOpen={() => setOpenStart(true)}
                    onClose={() => setOpenStart(false)}
                    format="DD/MM/YYYY"
                    label="Start Date"
                    value={
                      dateRange.start_date ? dayjs(dateRange.start_date) : null
                    }
                    onChange={(newDate) =>
                      handleDateRangeChange(newDate, "start_date")
                    }
                    slotProps={{
                      textField: {
                        onClick: () => setOpenStart(true),
                        size: "small",
                      },
                    }}
                  />
                  <DatePicker
                    open={openEnd}
                    onOpen={() => setOpenEnd(true)}
                    onClose={() => setOpenEnd(false)}
                    format="DD/MM/YYYY"
                    label="End Date"
                    value={
                      dateRange.start_date ? dayjs(dateRange.end_date) : null
                    }
                    onChange={(newDate) =>
                      handleDateRangeChange(newDate, "end_date")
                    }
                    slotProps={{
                      textField: {
                        onClick: () => setOpenEnd(true),
                        size: "small",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  variant="outlined"
                  onClick={downloadCSV}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  <Typography>Download CSV</Typography>
                  <DownloadIcon sx={{ mx: 1 }} />
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
        <Box sx={{ height: 650, width: "100%", background: "#fff" }}>
          <DataGrid
            rows={students}
            columns={columns}
            page={page}
            pageSize={10}
            rowsPerPageOptions={[10]}
            loading={loading}
            onPageChange={(page) => setPage(page)}
            rowCount={records}
            paginationMode="server"
            disableColumnFilter
            disableColumnSelector
          />
        </Box>
      </Card>
    </>
  );
};
export default Finance;
