import { toast } from "react-toastify";
import api from "../utils/api";
import axios from "axios";
import saveAs from "file-saver";

export async function getTestByGroupUserId(groupUserID: any) {
  try {
    const response = await api.get(`tests/${groupUserID}`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export const downloadPresignedFile = async (fileKey: string) => {
  try {
    const response = await api.get(`/tests/getFileUrl?key=${fileKey}`);

    if (response.data && response.data.data && response.data.data.url) {
      const url = response.data.data.url;

      const fileResponse = await axios.get(url, {
        responseType: "blob",
      });

      saveAs(fileResponse.data, fileKey);

      toast.success("File downloaded successfully");
    } else {
      toast.error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    toast.error("An error occurred while downloading the file");
  }
};

export async function updateTest(testId: string, file: File) {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(`tests/${testId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function createTest({
  groupUserID,
  test_name,
  file,
  evaluation,
  held_date,
  studentComment,
}: {
  groupUserID: string;
  test_name: string;
  file: File;
  evaluation: string;
  held_date: number;
  studentComment: string;
}) {
  try {
    const formData = new FormData();
    formData.append("groupUserID", groupUserID);
    formData.append("test_name", test_name);
    formData.append("file", file);
    formData.append("evaluation", evaluation);
    formData.append("held_date", String(held_date));
    formData.append("studentComment", studentComment);
    const response = await api.post("tests", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
