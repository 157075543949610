import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { createLocation } from "../../api/location";
import User from "../../interfaces/user";
import Form from "../form/Form";
import Input from "../form/Input";

const Index = () => {
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  let params = useParams();
  const {
    reset,
    control,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<User>();

  const onSubmit: SubmitHandler<{ location: string }> = async (data) => {
    setIsFetching(true);
    const res = await createLocation(data);
    if ("error" in res) {
      setIsFetching(false);
      return toast.error(res.message);
    } else {
      setIsFetching(false);
      reset({ location: "" });
      return toast.success("Location added successfully");
    }
  };

  return (
    <Form>
      <Card sx={{ padding: 2 }}>
        <CardContent>
          {!isUserLoading ? (
            <>
              <Input
                name="location"
                label={"City/Location"}
                control={control}
                error={!!errors.location}
              />

              <LoadingButton
                loading={isFetching}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                {!params.id ? "Create" : "Edit"}
              </LoadingButton>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 600,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    </Form>
  );
};

export default Index;
