import { FormEventHandler, FC } from "react";
interface FormInterface {
  children?: JSX.Element | JSX.Element[];
  onSubmit?: FormEventHandler;
}

const Form: FC<FormInterface> = ({ children, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      {children}
    </form>
  );
};

export default Form;
