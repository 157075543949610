import IDateRange from "../interfaces/dateRange";
import ErrorInterface from "../interfaces/error";
import api from "../utils/api";

export async function getDebitTables(
  dateRange: IDateRange,
  page: number
): Promise<any | ErrorInterface> {
  try {
    const queryParams: any = {};

    if (dateRange.start_date) {
      queryParams.start_date = dateRange.start_date;
    }
    if (dateRange.end_date) {
      queryParams.end_date = dateRange.end_date;
    }
    const response = await api.get(`debit`, {
      params: { ...queryParams, page },
    });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getCreditTables(
  dateRange: IDateRange,
  page: number
): Promise<any | ErrorInterface> {
  try {
    const queryParams: any = {};

    if (dateRange.start_date) {
      queryParams.start_date = dateRange.start_date;
    }
    if (dateRange.end_date) {
      queryParams.end_date = dateRange.end_date;
    }
    const response = await api.get(`credit`, {
      params: { ...queryParams, page },
    });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getUnpaidTable(
  dateRange: IDateRange,
  page: number,
  locations: any[]
): Promise<any | ErrorInterface> {
  try {
    const queryParams: any = {};

    if (dateRange.start_date) {
      queryParams.start_date = dateRange.start_date;
    }
    if (dateRange.end_date) {
      queryParams.end_date = dateRange.end_date;
    }
    if (locations) {
      queryParams.locations = locations.map((item) => item.value);
    }

    const response = await api.get(`unpaid`, {
      params: { ...queryParams, page },
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getBalanceTable(
  dateRange: IDateRange,
  page: number
): Promise<any | ErrorInterface> {
  try {
    const queryParams: any = {};

    if (dateRange.start_date) {
      queryParams.start_date = dateRange.start_date;
    }
    if (dateRange.end_date) {
      queryParams.end_date = dateRange.end_date;
    }
    const response = await api.get(`combined`, {
      params: { ...queryParams, page },
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getFinancesCSV(
  dateRange: IDateRange,
  selectedTable?: any,
  locations?: any[]
): Promise<any | ErrorInterface> {
  try {
    const queryParams: any = {};

    if (dateRange.start_date) {
      queryParams.start_date = dateRange.start_date;
    }
    if (dateRange.end_date) {
      queryParams.end_date = dateRange.end_date;
    }
    if (locations) {
      queryParams.locations = locations.map((item) => item.value);
    }
    const response = await api.post(
      `csv/${selectedTable}`,
      {},
      {
        params: { ...queryParams },
        responseType: "blob",
      }
    );

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
