import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  createUser,
  getUser,
  updateUser,
  downloadStudentXLSX,
} from "../../api/user";
import useAuth from "../../context/useAuth";
import User from "../../interfaces/user";
import { genders } from "../../utils/genders";
import { roles } from "../../utils/roles";
import Form from "../form/Form";
import Input from "../form/Input";
import Select from "../form/Select";

const registerSchema = (addPassw: boolean) =>
  yup
    .object({
      last_name: yup.string().required(),
      first_name: yup.string().required(),
      email: yup.string().email().required(),
      gender: yup.string().required(),
      phone_number: yup
        .string()
        .required()
        .matches(/^\+?\d{5,}$/),
      user_level: yup.number().required(),
      identity_card: yup.string().required(),
      location: yup.string().required(),
      ...(addPassw && {
        password: yup
          .string()
          .when("user_level", (value, schema) =>
            value && value[0] === 5 ? schema.nullable() : schema.required(),
          ),
      }),
      student_birthday: yup
        .string()
        .when("user_level", (value, schema) =>
          value && value[0] === 5 ? schema.required() : schema.nullable(),
        ),
    })
    .required();

const Index = () => {
  const { locations } = useAuth();
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [userLevelInputValue, setUserLevelInputValue] = useState<number | null>(
    null,
  );
  const navigate = useNavigate();
  let params = useParams();
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<User | any>({
    resolver: yupResolver(registerSchema(Boolean(!params.id))),
  });
  const onSubmit: SubmitHandler<User> = (data) => {
    if (!params.id) {
      registerUser(data);
    } else {
      editUser(data);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchUser(params.id);
    } else {
      reset({
        id: undefined,
        email: "",
        first_name: "",
        last_name: "",
        gender: "",
        phone_number: "",
        location: "",
        user_level: 5,
        identity_card: "",
        student_birthday: null,
      });
    }
  }, [params]);

  const fetchUser = async (id: string) => {
    setIsUserLoading(true);
    const res = await getUser(id);

    if ("error" in res) {
      setIsUserLoading(false);
      return toast.error(res.message);
    } else {
      setValue("id", res.id);
      setValue("email", res.email);
      setValue("last_name", res.last_name);
      setValue("first_name", res.first_name);
      setValue("gender", res.gender);
      setValue("phone_number", res.phone_number);
      setValue("location", res.location._id);
      setValue("user_level", res.user_level);
      setValue("identity_card", res.identity_card);
      setValue("student_birthday", res.student_birthday);
      setIsUserLoading(false);
    }
  };

  const isStudent = watch("user_level");

  const registerUser = async (data: User) => {
    setIsFetching(true);
    const res = await createUser(data);

    if ("error" in res) {
      setIsFetching(false);

      return toast.error(res.message);
    } else {
      navigate("/users");
      setIsFetching(false);

      return toast.success("User added successfully");
    }
  };

  const editUser = async (data: User) => {
    if (params.id) {
      setIsFetching(true);
      const res = await updateUser(params.id, data);

      if ("error" in res) {
        setIsFetching(false);

        return toast.error(res.message ? res.message : "Server error");
      }

      toast.success("User edited successfully");
      setIsFetching(false);
    }
  };
  const [open, setOpen] = useState<boolean>(false);

  const student_name = watch("first_name")?.replace(/\s/g, "");
  const student_Lastname = watch("last_name")?.replace(/\s/g, "");
  const studentFullName = `${student_name} ${student_Lastname}`;
  const studentId = params.id;

  return (
    <Form>
      <Card sx={{ padding: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {!params.id ? "Create User" : "Edit User"}
          </Typography>
          {!isUserLoading ? (
            <>
              <Select
                name="user_level"
                label={"Role"}
                setValue={setValue}
                getValue={(value) => setUserLevelInputValue(value)}
                options={roles}
                control={control}
                error={!!errors.user_level}
              />
              <Input
                name="first_name"
                label={"First Name"}
                control={control}
                error={!!errors.first_name}
              />
              <Input
                name="last_name"
                label={"Last Name"}
                control={control}
                error={!!errors.last_name}
              />
              <Input
                name="email"
                label={"Email"}
                control={control}
                error={!!errors.email}
              />
              {!params.id &&
                userLevelInputValue &&
                userLevelInputValue !== 5 && (
                  <Input
                    name="password"
                    label={"Password"}
                    control={control}
                    error={!!errors?.password}
                  />
                )}
              <Select
                name="gender"
                label={"Gender"}
                setValue={setValue}
                options={genders}
                control={control}
                error={!!errors.gender}
              />
              <Input
                name="phone_number"
                label={"Phone"}
                type="text"
                control={control}
                error={!!errors.phone_number}
              />
              <Select
                name="location"
                label={"Location"}
                setValue={setValue}
                options={locations}
                control={control}
                error={!!errors.location}
              />
              <Input
                name="identity_card"
                label={"ID Card"}
                control={control}
                error={!!errors.identity_card}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  format="DD/MM/YYYY"
                  label="Date Of Birth"
                  value={dayjs(watch("student_birthday"))}
                  sx={{ width: "100%", mt: "5px" }}
                  onChange={(newValue: Dayjs | null) => {
                    setValue("student_birthday", newValue);
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setOpen(true),
                      error: errors.student_birthday ? true : false,
                    },
                  }}
                />
              </LocalizationProvider>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <LoadingButton
                  loading={isFetching}
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  {!params.id ? "Create" : "Edit"}
                </LoadingButton>
                {params.id && isStudent === 5 && (
                  <LoadingButton
                    sx={{ marginTop: 2, marginLeft: 3 }}
                    variant="contained"
                    onClick={() =>
                      downloadStudentXLSX(studentId, studentFullName)
                    }
                  >
                    Download Student Data
                  </LoadingButton>
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 600,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    </Form>
  );
};

export default Index;
