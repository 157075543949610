import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EventIcon from "@mui/icons-material/Event";
import MovingIcon from "@mui/icons-material/Moving";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createLevel, getLevels, updateLevel } from "../../api/levels";
import Form from "../form/Form";
import Input from "../form/Input";

const Index = () => {
  const [create, setCreate] = useState<boolean>(false);
  const [levels, setLevels] = useState<any>([]);
  const [editLevel, setEditLevel] = useState<any>(null);
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<{ level: string; monthlyLength: number }>();

  const fetchLevels = async () => {
    const res = await getLevels();
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      return setLevels(res.data);
    }
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const handleEditLevel = async (levelId: string) => {
    setEditLevel(levelId);
    const oneLevel = levels.find((item: any) => item._id === levelId);
    setValue("level", oneLevel.level);
    setValue("monthlyLength", oneLevel.monthlyLength);
  };

  const handleSaveLevel = async (data: {
    level: string;
    monthlyLength: number;
  }) => {
    try {
      const monthlyLength = Number(data.monthlyLength);

      if (isNaN(monthlyLength)) {
        return toast.error("Monthly Length must be a number!");
      } else if (monthlyLength <= 0 || monthlyLength > 12) {
        return toast.error("Monthly Length must be between 1 and 12!");
      }
      const response = await updateLevel(editLevel, data);

      if ("error" in response) {
        return toast.error(response.message);
      }
      const updatedLevels = levels.map((level: any) =>
        level._id === editLevel ? { ...level, ...data } : level,
      );
      setLevels(updatedLevels);

      setEditLevel(null);
      reset();

      toast.success("Level updated successfully!");
    } catch (error) {
      console.error("Error updating level:", error);
      toast.error("An error occurred while updating the level.");
    }
  };

  const onSubmit: SubmitHandler<{
    level: string;
    monthlyLength: number;
  }> = async (data) => {
    if (!data.level) {
      return toast.error("Level name cannot be empty!");
    }

    const monthlyLength = Number(data.monthlyLength);

    if (isNaN(monthlyLength)) {
      return toast.error("Monthly Length must be a number!");
    } else if (monthlyLength <= 0 || monthlyLength > 12) {
      return toast.error("Monthly Length must be between 1 and 12!");
    }
    const res = await createLevel(data);
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      reset();
      setCreate(false);
      fetchLevels();
      toast.success("Level created successfully!");
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Levels</Typography>
        <Button variant="contained" onClick={() => setCreate(!create)}>
          Create <BookmarkAddIcon />
        </Button>
      </Box>
      <Grid container sx={{ mt: 5 }} spacing={2}>
        {create && (
          <Grid item xs={12} md={6} lg={4} sx={{ marginTop: "-24px" }}>
            <Typography variant="h6">Create Level</Typography>
            <Form>
              <Card
                sx={{
                  px: 2,
                  py: "18px",
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Input
                  name="level"
                  label="Level"
                  control={control}
                  error={!!errors.level}
                  size="small"
                />
                <Input
                  name="monthlyLength"
                  label="Monthly Length"
                  control={control}
                  error={!!errors.monthlyLength}
                  size="small"
                />
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </LoadingButton>
              </Card>
            </Form>
          </Grid>
        )}
        {levels?.map((level: any) => (
          <Grid item xs={12} md={6} lg={4} key={level._id}>
            {level?._id === editLevel ? (
              <Form>
                <Card
                  sx={{
                    px: 2,
                    py: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 5,
                  }}
                >
                  <Input
                    name="level"
                    label="Level"
                    control={control}
                    error={!!errors.level}
                    size="small"
                    disabled
                  />
                  <Input
                    name="monthlyLength"
                    label="Monthly Length"
                    control={control}
                    error={!!errors.monthlyLength}
                    size="small"
                  />
                  <Tooltip title="Save">
                    <IconButton
                      color="primary"
                      onClick={handleSubmit(handleSaveLevel)}
                    >
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>
                </Card>
              </Form>
            ) : (
              <Card
                sx={{
                  px: 2,
                  py: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <MovingIcon color="success" />
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <Typography>Level:</Typography>
                    <Typography variant="h6">{level.level}</Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                  <EventIcon color="success" />
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <Typography>Duration:</Typography>
                    <Typography variant="h6">{level.monthlyLength}</Typography>
                  </Box>
                </Box>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => handleEditLevel(level._id)}
                  >
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Index;
