import {
  Card,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUsersByNameAndLocation,
  getUsersCSV,
  removeUser,
} from "../../api/user";
import { genders } from "../../utils/genders";
import MultiSelect from "../form/MultiSelect";
import DownloadIcon from "@mui/icons-material/Download";
import saveAs from "file-saver";
import useAuth from "../../context/useAuth";
import { formatDate } from "../../utils/formatDate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

interface StudentOptions {
  email: string;
  name: string;
  gender: string;
  phone_number: string;
  location: string;
  identity_card: string;
  id: string;
}

const StudentList = () => {
  const { locations } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentOptions[]>([]);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const navigate = useNavigate();
  const [checkedLocation, setCheckedLocation] = useState<any[]>([]);

  const [openedMenu, setOpenedMenu] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(e.currentTarget);
    setOpenedMenu(id);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "student_birthday",
      headerName: "Birthday",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      valueGetter: (params: GridValueGetterParams) => {
        return genders[parseInt(params.row.gender) - 1]?.name;
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "identity_card",
      headerName: "ID Card",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "certifications",
      headerName: "Certifications",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => openMenu(e, params.row.id)}
          >
            <MoreVertIcon />
          </IconButton>
          {openedMenu === params.row.id && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/users/${params?.id}`);
                  closeMenu();
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#A250A6" }}
                >
                  View
                  <VisibilityIcon />
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickOpen();
                  setId(params.row?.id);
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#DA5050" }}
                >
                  Delete
                  <DeleteIcon />
                </Box>
              </MenuItem>
            </Menu>
          )}
        </div>
      ),
      flex: 1,
      maxWidth: 70,
    },
  ];
  const downloadCSV = async () => {
    const res = await getUsersCSV(["5"], checkedLocation, searchInput);
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return saveAs(
        res,
        `SPXEDU-Students - (${new Date().toLocaleDateString(
          "en-EN",
          options,
        )}).csv`,
      );
    }
  };
  const getStudentsByNameAndLocation = async () => {
    const { data: res, records } = await getUsersByNameAndLocation(
      ["5"],
      page,
      checkedLocation,
      searchInput,
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let _students = res.map((students: any) => {
        const student_birthday = students.student_birthday
          ? formatDate(new Date(students.student_birthday))
          : "";
        return {
          email: students.email,
          name: `${students.first_name} ${students.last_name}`,
          student_birthday: student_birthday,
          gender: students.gender,
          phone_number: students.phone_number,
          location: students.location.name,
          identity_card: students.identity_card,
          certifications:
            students.certifications[students.certifications.length - 1],
          id: students._id,
        };
      });
      setStudents(_students);
      setRecords(records);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudentsByNameAndLocation();
  }, [searchInput, checkedLocation, page]);

  const deleteUser = async (id: string) => {
    const res = await removeUser(id);
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      const _data = students.filter((user: any) => user.id !== id);
      setStudents(_data);
      toast.success("User deleted");
      setOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Are you sure you want to delete this user?</DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteUser(id)}
          >
            Delete
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Card elevation={0} sx={{ py: 2, px: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Students</Typography>
          <Button
            sx={{ mt: { xs: 2, lg: 0 } }}
            variant="outlined"
            onClick={() => downloadCSV()}
          >
            <Typography>Download CSV</Typography>
            <DownloadIcon sx={{ mx: 1 }} />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { lg: "center" },
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between",
            }}
          >
            <MultiSelect
              values={checkedLocation}
              setValues={setCheckedLocation}
              options={locations}
              label="Location"
            />
            <TextField
              size="small"
              sx={{ mr: { lg: 2 }, width: { xs: "100%", lg: "300px" } }}
              placeholder="Search by name ..."
              type="text"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ height: 650, width: "100%", background: "#fff" }}>
          <DataGrid
            rows={students}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            loading={loading}
            onPageChange={(e) => {
              setPage(e);
            }}
            rowCount={records}
            paginationMode="server"
            disableColumnFilter
            disableColumnSelector
          />{" "}
        </Box>
      </Card>
    </>
  );
};

export default StudentList;
