import Dashboard from "./../pages/Dashboard";
import Finance from "./../pages/Finance";
import Groups from "./../pages/Groups";
import GroupsCreate from "./../pages/GroupsCreate";
import Levels from "./../pages/Levels";
import Location from "./../pages/Location";
import OrganizationalStructure from "./../pages/OrganizationalStructure";
import PaymentsDone from "./../pages/PaymentsDone";
import Profile from "./../pages/Profile";
import Students from "./../pages/Students";
import Teachers from "./../pages/Teachers";
import Users from "./../pages/Users";
import UsersCreate from "./../pages/UsersCreate";
import Login from "./../pages/Login";
import ResetPassword from "./../pages/ResetPassword";
import RequestPasswordReset from "./../pages/RequestPasswordReset";

export const routes = {
  public: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/passwordReset",
      element: <ResetPassword />,
    },
    {
      path: "/requestPasswordReset",
      element: <RequestPasswordReset />,
    },
  ],
  private: [
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/about",
      element: <OrganizationalStructure />,
    },
    {
      path: "/groups",
      element: <Groups />,
    },
    {
      path: "/groups/create",
      element: <GroupsCreate />,
    },
    {
      path: "/groups/:id",
      element: <GroupsCreate />,
    },
  ],

  adminPrivate: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/students",
      element: <Students />,
    },
    {
      path: "/teachers",
      element: <Teachers />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/users/create",
      element: <UsersCreate />,
    },
    {
      path: "/users/:id",
      element: <UsersCreate />,
    },
    {
      path: "/finance",
      element: <Finance />,
    },
    {
      path: "/location",
      element: <Location />,
    },
    {
      path: "/levels",
      element: <Levels />,
    },
  ],

  managerPrivate: [
    {
      path: "/payments-done",
      element: <PaymentsDone />,
    },
    {
      path: "/payments-done/:id",
      element: <PaymentsDone />,
    },
  ],
};
