import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";

import menus from "../../enums/menus";
import useAuth from "../../context/useAuth";

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: any;
}

export default function ResponsiveDrawer(props: Props) {
  const location = useLocation();
  const { user, logout } = useAuth();

  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Typography
        color="primary"
        sx={{ p: 2 }}
        variant="h6"
        noWrap
        component="div"
      >
        Welcome back, {user?.first_name}
      </Typography>
      <Divider />
      <List>
        {menus.map((menu, index) => {
          if (
            user &&
            menu.access.includes(user.user_level ? user.user_level : 0)
          ) {
            return (
              <Link
                to={menu.path}
                key={index}
                style={{
                  textDecoration: "none",
                  color: "#111",
                }}
              >
                <ListItem
                  button
                  sx={{
                    ...(location.pathname.startsWith(menu.path) && {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    }),
                  }}
                >
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.name} />
                </ListItem>
              </Link>
            );
          }
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: {
            sm: `calc(100% - ${0}px)`,
            lg: `calc(100% - ${drawerWidth}px)`,
          },
          ml: { sm: `${0}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" }, color: "#fff" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              color: "#fff",
            }}
          >
            <Typography variant="h6" noWrap component="div">
              Speeex Education
            </Typography>
            <Button onClick={logout} variant="contained" color="error">
              Sign Out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: 0, lg: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: drawerWidth },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
