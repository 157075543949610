import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogTitle } from "@mui/material";
import Input from "../../form/Input";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import Form from "../../form/Form";
import * as yup from "yup";
import { createPaymentSent } from "../../../api/payments-sent";

interface Props {
  handleClose: () => void;
  open: boolean;
  getPaymentsDone: () => void;
}

export interface IFormInputPaymentsDone {
  sent_to: string;
  received_from: string;
  ammount: number;
}

const schema = yup
  .object({
    sent_to: yup.string().email().required(),
    received_from: yup.string().required(),
    ammount: yup.number().required(),
  })
  .required();

const CreateFormModal: React.FC<Props> = ({
  open,
  handleClose,
  getPaymentsDone,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInputPaymentsDone>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputPaymentsDone> = async (values) => {
    setLoading(true);
    const res = await createPaymentSent(values);

    if ("error" in res) {
      setLoading(false);
      setError(res.message);
      return toast.error(res.message);
    }

    toast.success(res.message);
    setLoading(false);
    getPaymentsDone();
    reset();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add Sent Payment</DialogTitle>
      <Box
        sx={{
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
            justifyContent: "center",
            backgroundColor: "#fff",
            height: "100%",
            alignItems: "center",
            px: 2,
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Received From"
                name="received_from"
                control={control}
                error={!!errors.received_from}
              />
              <Input
                label="Sent to"
                name="sent_to"
                control={control}
                error={!!errors.sent_to}
              />
              <Input
                label="Ammount"
                name="ammount"
                control={control}
                error={!!errors.ammount}
              />
              <Box
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Create
                </LoadingButton>
                <Button
                  size={"large"}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Form>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateFormModal;
