import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import Input from "../form/Input";
import Form from "../form/Form";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

import { Link as MuiLink } from "@mui/material";

import useAuth from "../../context/useAuth";
import logo from "../../assets/spxedulogo.jpg";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MuiLink href="https://rhenum.dev" target={"_blank"}>
        <Typography
          component="span"
          sx={{
            color: "text.secondary",
            fontSize: "inherit",
            textDecoration: "underline",
          }}
        >
          Rhenum L.L.C{" "}
        </Typography>
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface IFormInput {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const Index = () => {
  const { loading, error, login } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    login(data.email, data.password);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        justifyContent: "center",
        backgroundColor: "#fff",
        height: "100%",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        sx={{
          height: {
            xs: 100,
            sm: 120,
            md: 130,
            lg: 150,
            xl: 150,
          },
          mr: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 10,
            xl: 10,
          },
        }}
      >
        <img src={logo} width="100%" height="100%" alt="logo" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pl: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 10,
            xl: 10,
          },
          borderLeft: {
            xs: "",
            sm: "",
            md: "",
            lg: "3px solid rgba(0, 0, 0, 0.8)",
            xl: "3px solid rgba(0, 0, 0, 0.8)",
          },
        }}
      >
        <Box sx={{ mt: 1 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              align={"center"}
              gutterBottom
              variant="h6"
              sx={{ textTransform: "uppercase" }}
            >
              KyÇu per te vazhduar
            </Typography>
            <Input
              name="email"
              label={"Email"}
              control={control}
              error={!!errors.email}
            />
            <Input
              name="password"
              label={"Password"}
              control={control}
              error={!!errors.password}
            />
            <LoadingButton
              type="submit"
              fullWidth
              sx={{ marginTop: 2, marginBottom: 2 }}
              size={"large"}
              variant="contained"
              loading={loading}
            >
              Login
            </LoadingButton>
          </Form>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Link to="/requestPasswordReset">
            <Typography
              component="span"
              sx={{ color: "text.secondary", textDecoration: "underline" }}
            >
              Forgot Password?
            </Typography>
          </Link>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
