import React, { Dispatch, SetStateAction } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Box, Typography, Button } from "@mui/material";
import * as yup from "yup";
import Form from "../../form/Form";
import Input from "../../form/Input";
import { changePassword } from "../../../api/user";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface IFormInput {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup
  .object({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  })
  .required();

const ChangePassword: React.FC<ModalProps> = ({ open, setOpen }) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const res = await changePassword(data);

    if ("error" in res) {
      reset({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      return toast.error(res.message ? res.message : "Server error");
    }

    toast.success("Password Changed");
    setOpen(false);
    reset({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography gutterBottom variant="h5" component="div">
          Change Password
        </Typography>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="oldPassword"
            label={"Current Password"}
            control={control}
            error={!!errors.oldPassword}
            type="password"
          />
          <Input
            name="newPassword"
            label={"New Password"}
            control={control}
            error={!!errors.newPassword}
            type="password"
          />
          <Input
            name="confirmPassword"
            label={"Confirm New Password"}
            control={control}
            error={!!errors.confirmPassword}
            type="password"
          />
          <Button sx={{ mt: 2 }} variant="contained" type="submit">
            Change
          </Button>
        </Form>
      </Box>
    </Modal>
  );
};

export default ChangePassword;
