import Layout from "../component/template/Layout";
import Index from "../component/payments-done";

function Dashboard() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Dashboard;
