import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import EuroIcon from "@mui/icons-material/Euro";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
const menus = [
  {
    name: "Profile",
    icon: <AccountCircleIcon />,
    path: "/profile",
    access: [1, 2, 3, 4],
  },
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    access: [1, 2, 3],
  },
  {
    name: "Users",
    icon: <PeopleIcon />,
    path: "/users",
    access: [1, 2, 3],
  },
  {
    name: "Teachers",
    icon: <SchoolIcon />,
    path: "/teachers",
    access: [1, 2, 3],
  },
  {
    name: "Students",
    icon: <LocalLibraryIcon />,
    path: "/students",
    access: [1, 2, 3],
  },
  {
    name: "Groups",
    icon: <GroupsIcon />,
    path: "/groups",
    access: [1, 2, 3, 4],
  },
  {
    name: "Finance",
    icon: <EuroIcon />,
    path: "/finance",
    access: [1, 2, 3],
  },
  {
    name: "Payments Done",
    icon: <GroupsIcon />,
    path: "/payments-done",
    access: [1, 3],
  },
  {
    name: "Levels",
    icon: <CollectionsBookmarkIcon />,
    path: "/levels",
    access: [1, 2, 3],
  },
  {
    name: "About",
    icon: <InfoIcon />,
    path: "/about",
    access: [1, 2, 3, 4],
  },
];

export default menus;
