import * as yup from "yup";

export const weekDays = [
  {
    label: "Monday",
    value: 1,
  },
  {
    label: "Tuesday",
    value: 2,
  },
  {
    label: "Wednesday",
    value: 3,
  },
  {
    label: "Thursday",
    value: 4,
  },
  {
    label: "Friday",
    value: 5,
  },
  {
    label: "Saturday",
    value: 6,
  },
  {
    label: "Sunday",
    value: 7,
  },
];

export const schema = yup
  .object({
    teacher: yup.string().required(),
    name: yup.string().required(),
    location: yup.string().required(),
    start_date: yup.number().required(),
    end_date: yup.number().required(),
    schedule: yup
      .array(
        yup.object({
          day: yup.number().required(),
          time: yup.string().required(),
        }),
      )
      .required()
      .min(1),
  })
  .required();

export const scheduleSchema = yup.object({
  day: yup
    .number()
    .min(1, "Day must be at least 1")
    .max(7, "Day must be at most 7")
    .required("Day is required"),
  time: yup
    .string()
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
    .required("Time is required"),
});
