import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { toast } from "react-toastify";
import { getUserByRole } from "../../api/user";
import useAuth from "../../context/useAuth";

const style = {
  p: 2,
};

const Index = () => {
  const { locations } = useAuth();
  const [teachers, setTeachers] = useState<any[]>([]);
  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = async () => {
    const res = await getUserByRole("4");

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let _teachers = res.map((teacher: any) => {
        return {
          ...teacher,
          value: teacher._id,
          name: teacher.first_name + " " + teacher.last_name,
        };
      });

      setTeachers(_teachers);
    }
  };

  return (
    <Box>
      <Typography variant="h5" component="div" sx={{ pb: 2 }}>
        SPEEEX EDUCATION LLC
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ pb: 2 }}>
        SPEEEX Education, founded in November 2019 as a spin-off from SPEEEX
        LLC, is dedicated to providing top-tier training in Business Process
        Outsourcing (BPO) and Information & Communication Technology (ICT). Our
        mission is to equip the next generation with essential skills and
        knowledge for thriving in these dynamic fields. We serve a community of
        over 450 young learners across Prishtine, Ferizaj, and Vushtrri with a
        curriculum designed to meet modern workforce demands.
        <br />
        Our vision is to bridge the gap between academic knowledge and practical
        skills, preparing students for successful careers in BPO and ICT. We are
        committed to delivering high-quality education that promotes innovation,
        critical thinking, and professional growth, ensuring our students are
        competitive in the global market. Adhering to ISO 21000:2018 standards,
        we maintain the highest quality in our educational services.
        <br />
        Our comprehensive BPO training programs, advanced ICT courses, and
        practical learning experiences are crafted by industry experts.
        Upholding values of excellence, innovation, integrity, commitment, and
        inclusivity, we strive for the success of our students and staff,
        fostering an environment that respects and values diversity.
      </Typography>
      <Box
        sx={{
          width: { xs: "90vw", sm: "initial" },
          overflow: "auto",

          pb: 1,
        }}
      >
        <Box sx={{ width: { xs: "150%", sm: "100%" } }}>
          <Tree
            lineColor={"purple"}
            label={
              <Card sx={style}>
                <Box>
                  <Typography variant="h5" component="div" color="primary">
                    Vigan Disha
                  </Typography>
                </Box>
                <Box sx={{ pt: 2 }}>Managing Director</Box>
              </Card>
            }
          >
            <TreeNode
              label={
                <Card sx={style}>
                  <Box>
                    <Typography variant="h5" component="div" color="primary">
                      Arta Buxhovi
                    </Typography>
                  </Box>
                  <Box sx={{ pt: 2 }}>Manager</Box>
                </Card>
              }
            >
              {locations.map((l, index) => {
                let users = teachers.filter((t) => {
                  return t.location._id == l.value;
                });

                return (
                  <TreeNode
                    key={index}
                    label={
                      <Card sx={style}>
                        <Box>
                          <Typography
                            variant="h6"
                            component="div"
                            color="secondary"
                          >
                            SPEEEX EDUCATION
                          </Typography>
                        </Box>
                        <Box sx={{ pt: 2 }}>{l.name}</Box>
                      </Card>
                    }
                  >
                    {users.map((u, index) => {
                      return (
                        <TreeNode
                          key={index}
                          label={
                            <Card sx={style}>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  color="secondary"
                                >
                                  {u.name}
                                </Typography>
                              </Box>
                              <Box sx={{ pt: 1 }}>German</Box>
                            </Card>
                          }
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
            </TreeNode>
          </Tree>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
