import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PaymentDone from "../../../interfaces/payment-done";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import {
  createPaymentSent,
  getPaymentSent,
  updatePaymentDone,
} from "../../../api/payments-sent";
import Input from "../../form/Input";
import Form from "../../form/Form";
import * as yup from "yup";

interface Props {
  id: string;
  getPaymentsDone: () => void;
}

export interface IFormInputPaymentsDoneEdit {
  sent_to?: string;
  received_from?: string;
  ammount?: number;
}

const schema = yup
  .object({
    sent_to: yup.string().email().optional(),
    received_from: yup.string().optional(),
    ammount: yup.number().optional(),
  })
  .required();

const Single: React.FC<Props> = ({ id, getPaymentsDone }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sentPayment, setSentPayment] = useState<PaymentDone>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IFormInputPaymentsDoneEdit>({
    resolver: yupResolver(schema),
  });

  const getSentPayment = async () => {
    setLoading(true);
    const res = await getPaymentSent(id);

    if ("error" in res) {
      setLoading(false);
      return toast.error(res.message);
    } else {
      setValue("sent_to", res.sent_to);
      setValue("received_from", res.received_from);
      setValue("ammount", res.ammount);

      setSentPayment(res);
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<IFormInputPaymentsDoneEdit> = async (
    values
  ) => {
    setLoading(true);
    const res = await updatePaymentDone({ id, values });

    if ("error" in res) {
      setLoading(false);
      navigate("/payments-done");
      return toast.error(res.message);
    }

    toast.success(res.message);
    setLoading(false);
    reset();
    getPaymentsDone();
    navigate("/payments-done");
  };

  useEffect(() => {
    getSentPayment();
  }, []);

  return (
    <Box>
      <Card sx={{ padding: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Edit Sent Payment
          </Typography>
          {!loading ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Received From"
                name="received_from"
                control={control}
                error={!!errors.received_from}
              />
              <Input
                label="Sent to"
                name="sent_to"
                control={control}
                error={!!errors.sent_to}
              />
              <Input
                label="Ammount"
                name="ammount"
                control={control}
                error={!!errors.ammount}
              />
              <LoadingButton
                type="submit"
                loading={loading}
                sx={{ mt: 2 }}
                variant="contained"
              >
                Edit
              </LoadingButton>
            </Form>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 600,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Single;
