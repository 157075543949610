import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { SvgIconProps } from "@mui/material";

interface CardProps {
  title: string;
  activeCount: number;
  notActiveCount?: number;
  icon: React.ReactElement<SvgIconProps>;
  loader: boolean;
  active: string;
  inactive?: string;
}

const StatisticsCard: React.FC<CardProps> = ({
  title,
  activeCount,
  notActiveCount,
  icon,
  loader,
  active,
  inactive,
}) => {
  return (
    <Card>
      <CardHeader title={title} />

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {icon}

        {!loader ? (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex" }}>
              <Typography component="div">{active}</Typography>
              <Typography sx={{ color: "green", fontWeight: 500 }}>
                {activeCount}
              </Typography>
            </Box>
            {inactive && (
              <Box sx={{ display: "flex" }}>
                <Typography component="div">/{inactive}</Typography>
                <Typography sx={{ color: "red", fontWeight: 500 }}>
                  {notActiveCount}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <CircularProgress size={25} />
        )}
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
