import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  CardActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { formatDate } from "../../../utils/formatDate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  index: number;
  status: string;
  month: string;
  date: Date;
  handleClick: (
    debitAmout: number,
    status: "Paid" | "Unpaid",
    index: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  disabled: boolean;
  lastEditedBy: string;
  lastEditedDate: Date;
  cardsB2: boolean;
  amount: number;
  transferStatus: boolean;
  transferred: boolean | undefined;
}

const PaymentCard: React.FC<Props> = ({
  index,
  status,
  month,
  date,
  handleClick,
  disabled,
  lastEditedBy,
  lastEditedDate,
  cardsB2,
  amount,
  transferStatus,
  transferred,
}) => {
  const [loading, setLoading] = useState(false);
  const [debitAmount, setDebitAmount] = useState(0);
  const onClick = async () => {
    setLoading(true);
    handleClick(
      debitAmount,
      status === "Paid" ? "Unpaid" : "Paid",
      index,
      setLoading,
    );
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <Accordion
        sx={{
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>Month {index + 1} </Typography>
            <Typography
              sx={{
                color: status === "Unpaid" ? "#D98282" : "#097969",
                fontWeight: "700",
              }}
            >
              {status}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5" component="div">
            {month}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {date.getFullYear()}
          </Typography>
          {status == "Paid" && (
            <Box>
              {transferStatus && (
                <Typography
                  variant="body2"
                  sx={{ mb: 1.5, color: "#097969", fontWeight: "bold" }}
                >
                  Transferred
                </Typography>
              )}
              <Typography variant="body2">Paid Date</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {formatDate(lastEditedDate, {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <Typography variant="body2">Paid By</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {lastEditedBy}
              </Typography>
              <Typography variant="body2">Amount</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {amount}
              </Typography>
            </Box>
          )}
          <Typography variant="body2">
            Status:
            <Typography
              component="span"
              sx={{
                fontWeight: 800,
                ml: 2,
                ...(status === "Paid"
                  ? { color: "#097969" }
                  : { color: "#CD5C5C" }),
              }}
            >
              {status}
            </Typography>
          </Typography>
          {!transferred && (
            <CardActions>
              {status === "Unpaid" && (
                <TextField
                  size="small"
                  sx={{ mr: { lg: 2 }, width: { xs: "100%", lg: "300px" } }}
                  placeholder="Input amount"
                  type="number"
                  value={debitAmount}
                  onChange={(e) => {
                    setDebitAmount(parseFloat(e.target.value));
                  }}
                />
              )}

              {status === "Unpaid" && (
                <LoadingButton
                  sx={{ color: "#097969" }}
                  size="small"
                  onClick={() => onClick()}
                  disabled={disabled && !loading}
                  loading={loading}
                >
                  {status === "Unpaid" && "SET TO PAID"}
                </LoadingButton>
              )}
            </CardActions>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default PaymentCard;
