import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { useState } from "react";

interface Props {
  name: string;
  label: string;
  setValue?: any;
  control?: any;
  options?: any;
  error: boolean;
  getValue?: (value: any) => void;
  params?: any;
  levels?: any;
  onChange?: any;
}

function CustomSelect({
  name,
  label,
  setValue,
  options,
  control,
  error,
  getValue,
  params,
  levels,
  onChange,
}: Props) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
    setValue(name, event.target.value as string);
    if (getValue) getValue(event.target.value);
    if (onChange) onChange(event); 
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <FormControl
          error={error}
          fullWidth
          margin={"dense"}
          sx={{ textAlign: "left" }}
        >
          <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
          <Select
            labelId={`select-label-${label}`}
            id={`input-label-${label}`}
            value={field.value}
            label={label}
            onChange={!params ? handleChange : () => {}}
          >
            {options &&
              options.map((item: any) => {
                return (
                  <MenuItem
                    key={levels ? item._id : item.value}
                    value={levels ? item._id : item.value}
                  >
                    {levels ? item.level : item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    />
  );
}

export default CustomSelect;
