import ErrorInterface from "../interfaces/error";
import api from "../utils/api";

export async function createLocation(data: {
  location: string;
}): Promise<any | ErrorInterface> {
  try {
    const response = await api.post(`location`, data);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getLocations(): Promise<any | ErrorInterface> {
  try {
    const response = await api.get(`location`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
