import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";

interface PaginationProps {
  handleNext: () => void;
  handlePrev: () => void;
  page: number;
  totalPages: number;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  handleNext,
  handlePrev,
  page,
  totalPages,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      <Button variant="contained" onClick={handlePrev} disabled={page === 1}>
        Prev
      </Button>
      <Typography>{`Page ${page} of ${totalPages}`}</Typography>
      <Button
        variant="contained"
        onClick={handleNext}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </Box>
  );
};

export default PaginationComponent;
