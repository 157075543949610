import Index from "../component/organogram";
import Layout from "../component/template/Layout";

function OrganizationalStructure() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default OrganizationalStructure;
