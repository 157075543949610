import { toast } from "react-toastify";
import ErrorInterface from "../interfaces/error";
import User from "../interfaces/user";
import api from "../utils/api";
import downloadXLSXResponse from "../utils/downloadXLSXResponse";

export async function getCurrentUser(): Promise<User | ErrorInterface> {
  try {
    const response = await api.get("/api/user");
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function loginUser(params: {
  email: string;
  password: string;
}): Promise<{ token: string } | ErrorInterface> {
  try {
    const response = await api.post("auth/login", params);
    return { token: response.data.data };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getUsers(): Promise<User[] | ErrorInterface> {
  try {
    const response = await api.get("/users");
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getUser(id: string): Promise<User | ErrorInterface> {
  try {
    const response = await api.get(`users/${id}`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function updateUser(
  id: string | number,
  data: User,
): Promise<User | ErrorInterface> {
  try {
    const response = await api.put(`users/${id}`, { ...data });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function removeUser(id: string): Promise<User | ErrorInterface> {
  try {
    const response = await api.delete(`users/remove/${id}`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function createUser(params: {
  email: string;
  password?: string;
  last_name: string;
  first_name: string;
  gender: string;
  phone_number: string;
  location: string;
  user_level: number;
  identity_card: string;
  student_birthday?: any;
}): Promise<User | ErrorInterface> {
  try {
    const response = await api.post(`users`, params);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getUserByRole(
  user_level: string,
): Promise<any | ErrorInterface> {
  try {
    const response = await api.get(`users/role?role=${user_level}`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getUserByRoleAndName(
  user_level: string,
  page?: number,
  name?: string,
): Promise<User[] | ErrorInterface> {
  try {
    let query = "";
    if (page) {
      query = `&page=${page}`;
    }
    if (name) {
      query = query + `&name=${name}`;
    }
    const response = await api.get(
      `users/roleAndName?role=${user_level}${query}`,
    );
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function getUsersByNameAndLocation(
  user_level: string[],
  page?: number,
  checkedLocation?: any[],
  name?: string | undefined,
  no_pagination?: boolean,
): Promise<any | ErrorInterface> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l) => l.value);
    }
    const response = await api.get(`users/locationAndName`, {
      params: {
        user_level,
        page,
        location,
        no_pagination,
        ...(name && { name }),
      },
    });

    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getStudents(
  page?: number,
  checkedLocation?: any[],
  name?: string | undefined,
): Promise<any | ErrorInterface> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l) => (l.value ? l.value : l));
    }
    const response = await api.get(`users/getStudentsNotInGroup/`, {
      params: { page, location, ...(name && { name }) },
    });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function requestPasswordReset(
  email: string,
): Promise<{ status: boolean; message: string } | ErrorInterface> {
  try {
    const response = await api.post(`users/requestPasswordReset`, { email });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function resetPassword(
  email: string,
  reset_token: string,
  password: string,
  confirm_password: string,
): Promise<{ status: boolean; message: string } | ErrorInterface> {
  try {
    const response = await api.patch(
      `users/resetPassword`,
      { password, confirm_password },
      { params: { email, reset_token } },
    );
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function changePassword(
  data: any,
): Promise<{ status: boolean; message: string } | ErrorInterface> {
  try {
    const response = await api.patch(`users/changePassword`, {
      ...data,
    });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getUsersCSV(
  user_level: string[],
  checkedLocation?: any[],
  name?: string | undefined,
): Promise<any | ErrorInterface> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l) => l.value);
    }
    const response = await api.get(`users/csv`, {
      params: { user_level, location, name },
      responseType: "blob",
    });
    if (
      response.headers["content-type"] ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return response.data;
    }
  } catch (error: any) {
    return {
      error: true,
      message: error?.response?.data?.message,
    };
  }
}

export async function downloadStudentXLSX(
  studentId: string | undefined,
  filename: string,
) {
  try {
    const response = await api.get(`users/studentsXLXS/${studentId}`);
    if (response.data && response.data.body && response.data.isBase64Encoded) {
      downloadXLSXResponse(
        response.data,
        `SPEEEX-Education-Student-${filename}.xlsx`,
      );
    }
  } catch (e) {
    toast.error("Student was never a part of a group");
    console.error("Error downloading lectures file", e);
  }
}
