import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPaymentsSent, removePaymentDone } from "../../api/payments-sent";
import PaymentDone from "../../interfaces/payment-done";
import CreateFormModal from "./create-form-modal";
import List from "./list";
import Single from "./single";

const PaymentsDone = () => {
  const params = useParams();
  const [data, setData] = useState<PaymentDone[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoader] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPaymentsDone = async () => {
    const res = await getPaymentsSent();

    if ("error" in res) {
      setLoader(false);
      return toast.error(res.message);
    } else {
      setData(
        res
          .map((item: PaymentDone) => {
            item.id = item._id;
            return item;
          })
          .reverse()
      );
      setLoader(false);
    }
  };

  const deletePayment = async (id: string) => {
    setDeleteLoader(true);
    const res = await removePaymentDone(id);

    if ("error" in res) {
      setLoader(false);
      return toast.error(res.message);
    } else {
      const _data = data.filter((payment: any) => payment._id !== id);
      setData(_data);
      toast.success("Payment deleted");
      setDeleteLoader(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      setEditMode(true);
    }
    getPaymentsDone();
  }, []);

  useEffect(() => {
    if (params.id) {
      setEditMode(true);
    }
  }, [params]);

  if (editMode && params.id) {
    return <Single getPaymentsDone={getPaymentsDone} id={params.id} />;
  }

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box mb={2} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h5" component="div" gutterBottom>
          Payments Sent
        </Typography>
        <CreateFormModal
          handleClose={handleClose}
          open={open}
          getPaymentsDone={getPaymentsDone}
        />
        <Button variant="contained" onClick={handleClickOpen}>
          Create
        </Button>
      </Box>
      <List
        data={data}
        loading={loading}
        deleteLoading={deleteLoading}
        deletePayment={deletePayment}
      />
    </Box>
  );
};

export default PaymentsDone;
