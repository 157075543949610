import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../context/useAuth";

function RequireManagerRoute(props: any) {
  const { user } = useAuth();

  let location = useLocation();

  if (user && [1, 3].includes(user.user_level)) {
    return props.children;
  }

  return <Navigate to="/profile" state={{ from: location }} />;
}

export default RequireManagerRoute;
