import Layout from "../component/template/Layout";
import Index from "../component/location";

function Location() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Location;
