import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
          backgroundColor: "#f8f8f8",
          width: "100%",
          height: "100%",
        },
        body: {
          backgroundColor: "#f8f8f8",
          width: "100%",
          height: "100%",
          "& #root": {
            width: "100%",
            height: "100%",
          },
        },
        a: {
          color: "text.secondary",
          textDecoration: "none",
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#9C45A0",
    },
    secondary: {
      main: orange[500],
    },
  },
});

export default theme;
