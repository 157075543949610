import { IFormInputPaymentsDone } from "../component/payments-done/create-form-modal";
import { IFormInputPaymentsDoneEdit } from "../component/payments-done/single";
import ErrorInterface from "../interfaces/error";
import PaymentDone from "../interfaces/payment-done";
import api from "../utils/api";

export async function createPaymentSent(
  data: IFormInputPaymentsDone
): Promise<(IFormInputPaymentsDone & { message: string }) | ErrorInterface> {
  try {
    const response = await api.post(`additionalPayments`, data);

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getPaymentsSent(): Promise<
  PaymentDone[] | ErrorInterface
> {
  try {
    const response = await api.get(`additionalPayments`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getPaymentSent(
  id: string
): Promise<PaymentDone | ErrorInterface> {
  try {
    const response = await api.get(`additionalPayments/getOne`, {
      params: { id },
    });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function removePaymentDone(
  id: string
): Promise<PaymentDone | ErrorInterface> {
  try {
    const response = await api.delete(`additionalPayments/${id}`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function updatePaymentDone({
  id,
  values,
}: {
  id: string;
  values: IFormInputPaymentsDoneEdit;
}): Promise<(PaymentDone & { message: string }) | ErrorInterface> {
  try {
    const response = await api.patch(`additionalPayments/${id}`, values);
    return { ...response.data.data, message: response.data.message };
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
